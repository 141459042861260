/* Centrer le formulaire */
.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #007bff, #0056b3);
  font-family: 'Arial', sans-serif;
  padding: 1rem;
}

/* Style du formulaire */
.login-form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 400px;
  animation: fadeIn 0.5s ease-in-out;
}

/* Titre du formulaire */
.login-form h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}

/* Style des champs */
.login-form input {
  width: 100%;
  padding: 0.8rem;
  margin: 0.5rem 0;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.login-form input:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
  outline: none;
}

/* Bouton de connexion */
.login-form button {
  width: 100%;
  padding: 0.8rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-form button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-form button:active {
  transform: translateY(0);
  box-shadow: none;
}

/* Placeholder */
.login-form input::placeholder {
  color: #aaa;
}

/* Message d'erreur ou de notification */
.notify-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Section de connexion sociale */
.social-login {
  text-align: center;
  margin-top: 1.5rem;
}

.social-login h2 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #555;
}

.google-login {
  display: inline-block;
  background-color: #db4437;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.google-login:hover {
  background-color: #c23321;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.google-login:active {
  transform: translateY(0);
  box-shadow: none;
}

/* Animation d'apparition */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
